import React from 'react';
import styled from 'styled-components';
import NavBar from '../molecules/NavBar';
import profile from "../../public/profile_LHC.png";
import Footer from '../molecules/Footer';

const ExperienceVisual = ({years}) => {
    return (
        <ExperienceVisualContainer>
            {/* <ExperienceVisualMax /> */}
            <ExperienceVisualBar years={years} />
        </ExperienceVisualContainer>
    );
}

const AboutMePage = () => {
    const skills = [
        {name: "Python", startDate: "2015-04-01"},
        {name: "Analysis", startDate: "2017-04-01"},
        {name: "SQL", startDate: "2022-04-01"},
        {name: "React", startDate: "2023-10-01"},
        {name: "GCP", startDate: "2022-09-01"},
    ];

    const calculateExperience = (startDate) => {
        const start = new Date(startDate);
        const now = new Date();
        const diffYears = now.getFullYear() - start.getFullYear();
        return diffYears;
    }
    return (
        <>
        <NavBar />
        <Container>
            <ProfileSection>
                <ProfileImage src={profile} alt="Profile" />
                <Title>水上 淳 (Mizukami Atsushi)</Title>
                <Description>データ分析とAIの専門家。未来を変えるデータとAIの力を活用推進をしています。</Description>
            </ProfileSection>
        </Container>
        <Content>
            <Section>
                <SectionTitle>Career</SectionTitle>
                  <Timeline>
                    <TimelineItem>
                      <Year>2022</Year>
                      <Event>TDSE株式会社 データサイエンティスト</Event>
                    </TimelineItem>
                    <TimelineItem>
                      <Year>2022</Year>
                      <Event>総合研究大学院大学高エネルギー加速器科学研究科素粒子原子核専攻 単位満了退学</Event>
                    </TimelineItem>
                    <TimelineItem>
                      <Year>2017-2020</Year>
                      <Event>LHC-ATLAS実験に従事するため、スイス・ジュネーブに常駐</Event>
                    </TimelineItem>
                    <TimelineItem>
                      <Year>2015</Year>
                      <Event>総合研究大学院大学高エネルギー加速器科学研究科素粒子原子核専攻 入学</Event>
                    </TimelineItem>
                    <TimelineItem>
                      <Year>2015</Year>
                      <Event>東京理科大学理工学部物理学科 卒業</Event>
                    </TimelineItem>
                    <TimelineItem>
                      <Year>2011</Year>
                      <Event>東京理科大学理工学部物理学科 入学</Event>
                    </TimelineItem>
                    <TimelineItem>
                      <Year>2010</Year>
                      <Event>埼玉県立熊谷高等学校 卒業</Event>
                    </TimelineItem>
                  </Timeline>
            </Section>
            <Section>
                <SectionTitle>Skills</SectionTitle>
                <SkillsContainer>
                    {skills.map((skill, index) => {
                        const years = calculateExperience(skill.startDate);
                        return (
                            <SkillItem key={index}>
                                <SkillLabel>{skill.name}</SkillLabel>
                                <ExperienceDuration>{years} 年以上</ExperienceDuration>
                                <ExperienceContainer>
                                    <ExperienceVisual years={years} />
                                </ExperienceContainer>
                            </SkillItem>
                        );
                    })}
                </SkillsContainer>
            </Section>
            <Section>
                <SectionTitle>Recent Interests</SectionTitle>
                <Text>
                    データ活用をするための基盤作りから、データの可視化をする流れを推し進めるところに強い関心を持つ。 <br />
                    Chat-GPTを活用したアプリの作成。<br/>
                    データを連携してより使いやすいウェブ画面を作成すること。
                </Text>
            </Section>
            <Section>
                <SectionTitle>Hobbies</SectionTitle>
                <Text>
                  <ul>
                    <li>音楽鑑賞: Rock, EDM, Func, Blues</li>
                    <li>浅草周辺のグルメ探索</li>
                    <li>スポーツ観戦: ラグビー、野球(阪神ファンのつば九郎推し)</li>
                    <li>筋トレ</li>
                    <li>スペイン語(2024年7月、スペイン語技能検定6級合格🌸)</li>
                  </ul>
                </Text>
            </Section>
        </Content>
        <Footer />
        </>
    );
  };
  
  export default AboutMePage;

const Container = styled.div`
  background: linear-gradient(to bottom right, #f0f4f8, #d9e2ec);
  color: #333;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const ProfileSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
`

const ProfileImage = styled.img`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  border: 4px solid #2b6cb0;
  margin-bottom: 1rem;
`;

const Title = styled.h1`
  font-size: 2rem;
  margin-bottom: 0.5rem;
`

const Description = styled.p`
  font-size: 1.2rem;
  text-align: center;
  margin-bottom: 1rem;
  color: #666;
`

const Content = styled.div`
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
`

const Section = styled.div`
  margin-bottom: 2rem;
`

const SectionTitle = styled.h2`
  font-size: 1.5rem;
  border-bottom: 2px solid #007acc;
  padding-bottom: 0.5rem;
  margin-bottom: 1rem;
`

const Text = styled.p`
  font-size: 1rem;
  line-height: 1.6;
`

const SkillsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

const SkillItem = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`

const SkillLabel = styled.div`
  background: #007acc;
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 14px;
  color: #fff;
`

const ExperienceContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`

const ExperienceVisualBar = styled.div`
  height: 20px;
  background: #1e90ff;
  width: ${({ years }) => (years >= 10 ? 100 : (years / 10) * 100)}%;
  transition: width 0.3s ease-in-out;
`;

// const ExperienceVisualMax = styled.div`
//   position: absolute;
//   width: 100%;
//   height: 20px;
//   background: #e0e0e0;
//   border-radius: 5px;
//   top: 0;
//   left: 0;
// `

const ExperienceVisualContainer = styled.div`
  width: 100%;
  background: #e0e0e0;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
`

const ExperienceDuration = styled.span`
  font-size: 0.9em;
  margin-right: 10px;
  white-space: nowrap;
`

// const Skills = styled.ul`
//   list-style: none;
//   padding: 0;
// `

// const Skill = styled.li`
//   display: inline-block;
//   background: #007acc;
//   color: #fff;
//   padding: 0.5rem 1rem;
//   margin: 0.5rem;
//   border-radius: 20px;
//   font-size: 0.9rem;
// `

// const Card = styled.div`
//   background: white;
//   border-radius: 10px;
//   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
//   margin-bottom: 20px;
//   padding: 20px;
//   width: 80%;
//   transition: transform 0.2s;
  
//   &:hover {
//     transform: translateY(-10px);
//   }
// `


// const Icon = styled.span`
//   font-size: 24px;
//   margin-right: 10px;
// `

const Timeline = styled.ul`
  list-style-type: none;
  padding: 0;
`

const TimelineItem = styled.li`
  display: flex;
  margin-bottom: 10px;
`

const Year = styled.span`
  font-weight: bold;
  margin-right: 10px;
  width: 60px;
`

const Event = styled.span`
  font-size: 1em;
`