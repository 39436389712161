const getFuctionsBaseURL = () => {
    console.log(process.env.NODE_ENV);
    if (process.env.NODE_ENV === "development") {
        return "http://127.0.0.1:5001/blog-99583/asia-northeast1";
        // return "https://asia-northeast1-blog-99583.cloudfunctions.net";
    } else {
        return "https://asia-northeast1-blog-99583.cloudfunctions.net";
    }
};

export const getBlogs = async () => {
    const baseURL = getFuctionsBaseURL();
    try {
        const response = await fetch(`${baseURL}/getBlogs`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        });
        if (!response.ok) {
            throw new Error("Network response was not ok!");
        }
        const blogs = await response.json();
        return blogs;
    } catch (error) {
        console.error("Error during fetching data: ", error)
    }
};

export const getBlogById = async (id) => {
    try {
        const baseURL = getFuctionsBaseURL();
        const response = await fetch(`${baseURL}/getBlogById?id=${id}`);
        const blog = await response.json();
        return blog;
    } catch (error) {
        throw new Error("Failed to retrieve blog data")
    }
};

export const likeBlog = async (id, newLikes) => {
    const baseURL = getFuctionsBaseURL();
    const response = await fetch(`${baseURL}/likeBlog`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ id, newLikes }),
    });

    const result = await response.text();
    return result;
};
