import NavBar from "../molecules/NavBar";
import Footer from "../molecules/Footer";
import styled from "styled-components";

const TermsOfServices = () => {
    return (
        <>
        <NavBar />
        <Container>
                <h1>利用規約</h1>
                <Section>
                    <h2>1. はじめに</h2>
                    <p>この利用規約（以下「本規約」といいます。）は、当サイトの利用条件を定めるものです。本サイトを利用することで、本規約に同意したものとみなされます。</p>
                </Section>
                <Section>
                    <h2>2. 禁止事項</h2>
                    <p>当サイトの利用にあたり、以下の行為を禁止します：</p>
                    <ul>
                        <li>法令または公序良俗に反する行為</li>
                        <li>犯罪行為に関連する行為</li>
                        <li>当サイトの運営を妨害する行為</li>
                        <li>他のユーザーの個人情報を不正に取得する行為</li>
                    </ul>
                </Section>
                <Section>
                    <h2>3. 免責事項</h2>
                    <p>当サイトのコンテンツや情報について、その正確性や完全性を保証するものではありません。当サイトの利用により生じた損害について、当サイトは一切責任を負いません。</p>
                </Section>
                <Section>
                    <h2>4. 規約の変更</h2>
                    <p>本規約は、必要に応じて変更することがあります。変更後の規約は、当サイトに掲載された時点で有効となります。</p>
                </Section>
                <Section>
                    <h2>5. お問い合わせ</h2>
                    <p>本規約に関するお問い合わせは、コンタクトフォームからご連絡ください。</p>
                </Section>
            </Container>
            <Footer />
        </>
    );
}

export default TermsOfServices;

const Container = styled.div`
    padding: 20px;
`;

const Section = styled.section`
    margin-bottom: 20px;
`;