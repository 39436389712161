import NavBar from "../molecules/NavBar";
import Footer from "../molecules/Footer";
import styled from "styled-components";

const PrivacyPolicy = () => {
    return (
        <>
            <NavBar/>
            <Container>
            <Title>プライバシーポリシー</Title>
                <Section>
                    <SubTitle>1. 個人情報の収集</SubTitle>
                    <Text>当サイトでは、お問い合わせフォームの送信時に、名前、メールアドレスなどの個人情報を収集することがあります。これらの情報は、お問い合わせへの対応やサービスの提供に使用されます。</Text>
                </Section>
                <Section>
                    <SubTitle>2. 個人情報の利用目的</SubTitle>
                    <Text>収集した個人情報は、以下の目的で利用されます。</Text>
                    <List>
                    <ListItem>お問い合わせ対応</ListItem>
                    <ListItem>サービスの提供・改善</ListItem>
                    <ListItem>新サービスのお知らせ</ListItem>
                    </List>
                </Section>
                <Section>
                    <SubTitle>3. 個人情報の第三者提供</SubTitle>
                    <Text>当サイトでは、法令に基づく場合を除き、個人情報を第三者に提供することはありません。</Text>
                </Section>
                <Section>
                    <SubTitle>4. 個人情報の管理</SubTitle>
                    <Text>個人情報の漏洩、紛失、毀損を防止するために、適切な安全対策を実施しています。</Text>
                </Section>
                <Section>
                    <SubTitle>5. プライバシーポリシーの変更</SubTitle>
                    <Text>本プライバシーポリシーは、必要に応じて変更されることがあります。変更後のポリシーは、当サイトに掲載された時点で有効となります。</Text>
                </Section>
                <Section>
                    <SubTitle>6. お問い合わせ</SubTitle>
                    <Text>プライバシーポリシーに関するお問い合わせは、コンタクトフォームよりお願いします。</Text>
                </Section>
            </Container>
            <Footer />
        </>
      )
}

export default PrivacyPolicy;

const Container = styled.div`
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
`

const Title = styled.h1`
    font-size: 2em;
    margin-bottom: 20px;
`

const Section = styled.div`
    margin-bottom: 20px;
`

const SubTitle = styled.div`
    font-size: 1.5em;
    margin-bottom: 10px;
`

const Text = styled.div`
    font-size: 1em;
    line-height: 1.6;
`

const List = styled.ul`
    list-stype-type: disc;
    margin-left: 20px;
`

const ListItem = styled.li`
    font-size: 1em;
    line-height: 1.6;
`