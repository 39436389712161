import styled from 'styled-components'
import BaseLink from '../atoms/BaseLink'
import logo from "../../public/logo.png";

const NavBar = () => {
  return (
    <Header>
        <Navigation>
            <Image src={logo} alt="logo" />
            <NavLink href="/">Home</NavLink>
            <NavLink href="/about">About</NavLink>
            <NavLink href="/blog">Blog</NavLink>
            <NavLink href="/contact">Contact</NavLink>
        </Navigation>
    </Header>
  )
}

export default NavBar

const Header = styled.header`
    background-color: #fff;
    padding: 6px 8px;
    margin: 0px;
    align-items: center;
`

const Navigation = styled.nav`
    display: flex;
    align-items: center;
    gap: 15px;
    margin-left: 15px;
`

const Image = styled.img`
    width: 60px;
    height: 60px;
    border-radius: 50%;
`

const NavLink = styled(BaseLink)`
    text-decoration: none;
    color: #000;
    margin-left: 15px;
`